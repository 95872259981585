var addWordColumns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: '10%',
  scopedSlots: {
    customRender: 'id'
  }
  // align:'center'
}, {
  title: '商品类目',
  dataIndex: 'parentcategoryname',
  key: 'parentcategoryname',
  width: '20%',
  scopedSlots: {
    customRender: 'parentcategoryname'
  }
  // align:'center'
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  width: '10%',
  scopedSlots: {
    customRender: 'brandname'
  }
  // align:'center'
}, {
  title: '系列名称',
  dataIndex: 'seriename',
  key: 'seriename',
  width: '10%',
  scopedSlots: {
    customRender: 'seriename'
  }
  // align:'center'
}, {
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  width: '10%',
  scopedSlots: {
    customRender: 'sku'
  }
  // align:'center'
}, {
  title: '商品图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  width: '15%',
  scopedSlots: {
    customRender: 'imgurls'
  }
  // align:'center'
}, {
  title: '发售价($)',
  dataIndex: 'saleprice',
  key: 'saleprice',
  width: '20%',
  scopedSlots: {
    customRender: 'saleprice'
  }
  // align:'center'
}];

export { addWordColumns };