var columns = [
// {
//     title: '序号',
//     dataIndex: 'id',
//     key: 'id',
//     width: '10%',
//     scopedSlots: {
//         customRender: 'id'
//     },
//     align:'center'
// },
{
  title: '热搜词',
  dataIndex: 'name',
  key: 'name',
  width: '15%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '商品',
  dataIndex: 'productnum',
  key: 'productnum',
  width: '15%',
  scopedSlots: {
    customRender: 'productnum'
  }
}, {
  title: '开始时间—结束时间',
  dataIndex: 'begintime',
  key: 'begintime',
  width: '40%',
  scopedSlots: {
    customRender: 'begintime'
  }
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  fixed: "right",
  width: 250,
  scopedSlots: {
    customRender: 'actions'
  }
}];
export { columns };